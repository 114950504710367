export interface Config {
  serverUrl: string
}

const env = (window as any).env
const dataset = document.head.dataset

export const config: Config = {
  serverUrl: dataset?.serverUrl || env?.SERVER_URL || '',
}
